<template>
  <div class="my-5 package-details-table bg-white overflow-hidden rounded-14">
    <div
      class="
        package-details-table__title
        text-font-main text-med-14
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <span  class="text-dark">
        {{$t(title) }}
      </span>




    </div>
    <div class="package-details-table__body">
      <p class="text-reg-14 text-font-secondary m-0">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

