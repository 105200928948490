<template>
  <validation-observer
    ref="form"
    #default="{ invalid }"
    tag="form"
    class="repeater__form"
    style="overflow: visible"
  >
    <b-modal
      v-model="show"
      size="lg"
      content-class="package-modal rounded-14 mx-4"
      no-fade
    >
      <template slot="modal-header-close">
        <close-icon />
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('requests.modal.reject') }}
          </h3>
        </div>
      </template>

      <div class="text-med-16 mb-2 ">
        {{ heading }}
      </div>
      <b-row>
        <b-col md="12">
          <text-area
            id="package-ad"
            v-model="form.content"
            :label="$t('requests.modal.reject_reason')"
            :placeholder="$t('requests.modal.reject_reason_placeholder')"
            name="package-ad"
            rules="required"
            rows="5"
          />
        </b-col>

      </b-row>

      <template slot="modal-footer">
        <wameed-btn
          classes="  text-med-14 text-white mx-0 rounded-10"
          :title="$t('common.send')"
          :disabled="invalid"
          type="submit"
          variant="main"
          @submitAction="submit()"
        />
        <wameed-btn
          classes="  text-med-14 text-font-secondary mx-0 rounded-10 "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          @submitAction="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import { mapActions } from 'vuex';
import TextArea from '@/components/TextArea';

export default {
  components: {
    TextArea,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    serviceId: {
      type: [Number, String],
      default: null,
    },
    type: '',

  },

  data() {
    return {
      form: {
        content: '',
      },
      showSuccessModal: false,

    };
  },
  computed: {
    heading() {
      const tral = `requests.modal.service_reject_${this.type}`;
      return this.$t(tral);
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions(['rejectServiceRequest']),
    submit() {
      this.rejectServiceRequest({
        ...this.form,
        id: this.serviceId,
        type: this.type,
      }).then(() => {
        this.$emit('onComplete', true);
        this.show = false;
        this.showModal = true;
        this.form.content = '';
        this.$store.commit('loadingFinish');
      });
    },
    closeModal() {
      this.show = false;
    },
  },

};
</script>
