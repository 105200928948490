<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
          :title="$t('packages.details_title')"
          :subTitle="$t('packages.details_subtitle')"
      />
    </div>
    <section class="wameed-dashboard-page-content_body">
      <div class="mx-4">
        <b-row class="">
          <b-col md="6" class="my-4">
            <package-details-table
                :packageDetails="getPackageDetails"
            ></package-details-table>
          </b-col>
          <b-col md="6" class="my-4">
            <user-info
                v-if="getPackageDetails.provider"
                :title="'common.provided_by'"
                :id="getPackageDetails.provider.id"
                :name="getPackageDetails.provider.name"
                :email="getPackageDetails.provider.email"
                :image="getPackageDetails.provider.image"
            />
            <package-details-images
                :images="getPackageDetails.images"
                :image="getPackageDetails.image"
            ></package-details-images>

            <div class="my-5"
                 v-if="getCurrentRouteType && (getPackageDetails.request && (
                (getPackageDetails.request.cancel_reason && getPackageDetails.others.cancel_status === 0 && getPackageDetails.status !=='expired') ||
                 (getPackageDetails.request.feature_days && getPackageDetails.others.feature_status === 0 && getPackageDetails.status ==='soon')))">

              <request-service-card :service="getPackageDetails.request" :show-detail="false" card-type="both" />
            </div>


            <div class="package-orders" v-if="getCurrentRouteType">

              <template
                  v-if="
                  'others' in getPackageDetails &&
                  getPackageDetails.others !== null
                "
              >

                <reason-message
                    v-if="getPackageDetails.others.cancel_reason && getPackageDetails.others.cancel_status === 0"
                    title="requests.cancel.reason"
                    :text="getPackageDetails.others.cancel_reason"
                ></reason-message>

                <div v-if="getPackageDetails.request && (getPackageDetails.request.cancel_reason && getPackageDetails.others.cancel_status === 0 && getPackageDetails.status !=='expired')">
                  <div class="px-4 py-3">
                    <wameed-btn
                        classes="  text-med-14 text-white rounded-10"
                        :title="$t('common.accept_request')"
                        :disabled="invalid"
                        block
                        variant="main"
                        @submitAction="()=>approve('cancel')"
                    />
                  </div>
                  <div class="px-4 py-3">
                    <wameed-btn
                        classes="  text-med-14 text-font-secondary rounded-10 "
                        :title="$t('common.reject')"
                        block
                        type="button"
                        variant="gray"
                        @submitAction="()=>reject('cancel')"
                    />
                  </div>
                </div>



                <reason-message
                    v-if="getPackageDetails.others.feature_days && getPackageDetails.others.feature_status === 0"
                    title="requests.feature.days"
                    :text="getPackageDetails.others.feature_days + ' ' + $t('packages.days')"
                ></reason-message>

                <div v-if="getPackageDetails.request &&  (getPackageDetails.request.feature_days && getPackageDetails.others.feature_status === 0 && getPackageDetails.status ==='soon')">
                  <div class="px-4 py-3">
                    <wameed-btn
                        classes="  text-med-14 text-white rounded-10"
                        :title="$t('common.accept_request')"
                        block
                        variant="main"
                        @submitAction="()=>approve('feature')"
                    />
                  </div>
                  <div class="px-4 py-3">
                    <wameed-btn
                        classes="  text-med-14 text-font-secondary rounded-10 "
                        :title="$t('common.reject')"
                        block
                        type="button"
                        variant="gray"
                        @submitAction="()=>reject('feature')"
                    />
                  </div>
                </div>
              </template>
            </div>


          </b-col>
        </b-row>
      </div>
      <fetch-service-bookings></fetch-service-bookings>
    </section>
    <service-reject-modal
        :visible="showModal"
        :service-id="getPackageDetails.id"
        :type="routeType"
        @close="showModal = false"
        @onComplete="()=>loadData()"
    ></service-reject-modal>
  </div>
</template>

<script>
import PackageDetailsTable from '@/components/pages/applicants/PackageDetailsTable.vue';
import PackageDetailsImages from '@/components/pages/applicants/PackageDetailsImages.vue';
import {mapActions, mapGetters} from 'vuex';
import FetchBookings from '@/components/pages/bookings/FetchBookings.vue';
import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import CancelPackageModal from '@/components/PackageDetailsModals/CancelPackageModal.vue';
import SpecialPackageModal from '@/components/PackageDetailsModals/SpecialPackageModal.vue';
import WarningModal from '@/components/WarningModal.vue';
import CancelStatusAlert from '@/components/CancelStatusAlert.vue';
import SpecialPackageStatusAlert from '@/components/SpecialPackageStatusAlert.vue';
import ReasonMessage from '@/components/ReasonMessage.vue';
import FetchServiceBookings from '@/components/fetchServiceBookings.vue';
import UserInfo from "@/components/bookings/UserInfo";
import RequestServiceCard from "@/views/pages/requests/services/components/RequestServiceCard";
import ServiceRejectModal from "@/views/pages/requests/services/components/ServiceRejectModal";

export default {
  components: {
    ServiceRejectModal,
    RequestServiceCard,
    UserInfo,
    PackageDetailsTable,
    PackageDetailsImages,
    FetchBookings,
    PageHeader,
    WameedBtn,
    CancelPackageModal,
    SpecialPackageModal,
    WarningModal,
    CancelStatusAlert,
    SpecialPackageStatusAlert,
    ReasonMessage,
    FetchServiceBookings,
  },
  data() {
    return {
      showModal: false,
      routeType:""
    };
  },
  computed: {
    ...mapGetters({
      getPackageDetails: 'getPackageDetails',
    }),

    getCurrentRouteType() {
      return this.$router.currentRoute.params.type;

    },
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.loadPackagesDetails(this.$router.currentRoute.params.id);
      }
    },
  },
  mounted() {
    this.loadPackagesDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadPackagesDetails: 'loadPackagesDetails',
      loadFile: 'loadFile',
      approveRequest: 'approveServiceRequest'
    }),
    checkOrderSpecial(status) {
      if (this.getPackageDetails) {
        const isActiveOrSoon = this.getPackageDetails.status !== 'soon';

        return isActiveOrSoon || status !== null;
      }
    },
    checkCancelPackage(status) {
      if (this.getPackageDetails) {
        const isActiveOrSoon = this.getPackageDetails.status !== 'soon'
            || this.getPackageDetails.status !== 'active';

        return isActiveOrSoon || status !== null;
      }
    },
    currentDate() {
      if (this.getPackageDetails.others) {
        const current = this.getPackageDetails.others.admin_cancel_message;
        const date = this.moment(current).format('DD/MM/YYYY');
        if (date === 'Invalid date') {
          return '';
        }
        return date;
      }
      return '';
    },


    reject(type) {
      this.routeType=type;
      this.showModal = true;
    },


    approve(type) {
      this.approveRequest({
        id: this.$router.currentRoute.params.id,
        type: type,
      });

    }

  },
};
</script>
